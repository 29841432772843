export const FretboardNoteArray = [
	// High E string
	{
		string: 6,
		note: 'E',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: true,
	},
	{
		string: 6,
		note: 'F',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'F#',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'G',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'G#',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'A',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'A#',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'B',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'C',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'C#',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'D',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'D#',
		isActive: false,
		isTop: false,
		isBottom: true,
		isNut: false,
	},
	{
		string: 6,
		note: 'E',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
		isOctave: true,
	},

	// B string

	{
		string: 5,
		note: 'B',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: true,
	},
	{
		string: 5,
		note: 'C',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'C#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'D',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'D#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'E',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'F',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'F#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'G',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'G#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'A',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'A#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 5,
		note: 'B',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
		isOctave: true,
	},

	// G string

	{
		string: 4,
		note: 'G',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: true,
	},
	{
		string: 4,
		note: 'G#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'A',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'A#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'B',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'C',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'C#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'D',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'D#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'E',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'F',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'F#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 4,
		note: 'G',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
		isOctave: true,
	},

	// D string

	{
		string: 3,
		note: 'D',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: true,
	},
	{
		string: 3,
		note: 'D#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'E',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'F',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'F#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'G',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'G#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'A',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'A#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'B',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'C',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'C#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 3,
		note: 'D',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
		isOctave: true,
	},

	// A string
	{
		string: 2,
		note: 'A',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: true,
	},
	{
		string: 2,
		note: 'A#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'B',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'C',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'C#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'D',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'D#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'E',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'F',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'F#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'G',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'G#',
		isActive: false,
		isTop: false,
		isBottom: false,
		isNut: false,
	},
	{
		string: 2,
		note: 'A',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
		isOctave: true,
	},

	// LOW E string
	{
		string: 1,
		note: 'E',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: true,
	},
	{
		string: 1,
		note: 'F',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'F#',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'G',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'G#',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'A',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'A#',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'B',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'C',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'C#',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'D',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'D#',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
	},
	{
		string: 1,
		note: 'E',
		isActive: false,
		isTop: true,
		isBottom: false,
		isNut: false,
		isOctave: true,
	},
];
